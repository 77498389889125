<!-- 票据账单 -->
<template>
	<div :class="themeClass">
		<div class="margin-t-20 ">
			<div class="flex-row-space-between-center">
				<div class="font-weight700 font-size18 billtext flex-row">票据账单</div>
				<div class="block flex-row">
					<el-date-picker v-model="value1" type="date" placeholder="选择日期">
					</el-date-picker>
				</div>
			</div>
			<div class="topbgc flex-row-space-between-center">
				<div class="font-weight700 font-size13">本月</div>
				<div class="color-theme font-weight700 font-size13">
					<span class="margin-r-35">收入：￥1,000</span>
					<span>支出：￥9,999.00 </span>
				</div>
			</div>
			<div class="billsitems" v-for="(item, index) in currentMonthList" :key="index">
				<div class="padding-lr-15 flex-row-space-between-center">
					<div>
						<div class="font-size14 text-left line-height39">
							{{ item.billsCode }}
						</div>
						<div class="font-size13 text-left font-color-999">
							{{ item.billsDate }}
						</div>
					</div>
					<div>
						<div class="text-right line-height39" :class="item.isexpend == 1 ? 'red' : 'black'">
							￥{{ item.billsMoney }}
						</div>
						<div class="font-color-999 font-size13">
							{{ item.billsbussinessNmae }}
						</div>
					</div>
				</div>
			</div>
			<div class="padding-b-50">
				<div class="topbgc flex-row-space-between-center">
					<div class="font-weight700 font-size13">2020-6</div>
					<div class="color-theme font-weight700 font-size13">
						<span class="margin-r-35">收入：￥1,000</span>
						<span>支出：￥9,999.00 </span>
					</div>
				</div>
				<div class="billsitems" v-for="(item, index) in currentMonthList" :key="index +'B'">
					<div class="padding-lr-15 flex-row-space-between-center">
						<div>
							<div class="font-size14 text-left line-height39">
								{{ item.billsCode }}
							</div>
							<div class="font-size13 text-left font-color-999">
								{{ item.billsDate }}
							</div>
						</div>
						<div>
							<div class="text-right line-height39" :class="item.isexpend == 1 ? 'red' : 'black'">
								￥{{ item.billsMoney }}
							</div>
							<div class="font-color-999 font-size13">
								{{ item.billsbussinessNmae }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		data() {
			return {
				value1: "",
				currentMonthList: [{
						billsCode: "店铺商品购买店铺商品(票据号：123456786546)",
						billsDate: "2020-02-14 13:20:30",
						billsMoney: "+20",
						isexpend: 1,
						billsbussinessNmae: "乌鲁木齐市建材有限公司",
					},
					{
						billsCode: "店铺商品购买店铺商品(票据号：123456786546)",
						billsDate: "2020-02-14 13:20:30",
						billsMoney: -600,
						isexpend: 0,
						billsbussinessNmae: "乌鲁木齐市建材有限公司",
					},
					{
						billsCode: "店铺商品购买店铺商品(票据号：123456786546)",
						billsDate: "2020-02-14 13:20:30",
						billsMoney: -600,
						isexpend: 0,
						billsbussinessNmae: "乌鲁木齐市建材有限公司",
					},
					{
						billsCode: "店铺商品购买店铺商品(票据号：123456786546)",
						billsDate: "2020-02-14 13:20:30",
						billsMoney: -600,
						isexpend: 0,
						billsbussinessNmae: "乌鲁木齐市建材有限公司",
					},

					{
						billsCode: "店铺商品购买店铺商品(票据号：123456786546)",
						billsDate: "2020-02-14 13:20:30",
						billsMoney: -600,
						isexpend: 0,
						billsbussinessNmae: "乌鲁木齐市建材有限公司",
					},
					{
						billsCode: "店铺商品购买店铺商品(票据号：123456786546)",
						billsDate: "2020-02-14 13:20:30",
						billsMoney: -600,
						isexpend: 0,
						billsbussinessNmae: "乌鲁木齐市建材有限公司",
					},
					{
						billsCode: "店铺商品购买店铺商品(票据号：123456786546)",
						billsDate: "2020-02-14 13:20:30",
						billsMoney: 700,
						isexpend: 1,
						billsbussinessNmae: "乌鲁木齐市建材有限公司",
					},
					{
						billsCode: "店铺商品购买店铺商品(票据号：123456786546)",
						billsDate: "2020-02-14 13:20:30",
						billsMoney: -600,
						isexpend: 0,
						billsbussinessNmae: "乌鲁木齐市建材有限公司",
					},
					{
						billsCode: "店铺商品购买店铺商品(票据号：123456786546)",
						billsDate: "2020-02-14 13:20:30",
						billsMoney: -600,
						isexpend: 0,
						billsbussinessNmae: "乌鲁木齐市建材有限公司",
					},
				],
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		methods: {},
	};
</script>
<style scoped lang="scss">
	.red {
		color: #ff0000;
	}

	.black {
		color: #000;
	}

	.line-height39 {
		line-height: 39px;
	}

	.billtext {
		line-height: 36px;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.topbgc {
		width: 917px;
		height: 35px;
		background: #f8f9fe;
		padding: 0 15px;
		margin: 11px auto;
	}

	::v-deep.el-input--suffix .el-input__inner {
		line-height: 32px !important;
		height: 32px !important;
	}

	::v-deep .el-input__icon {
		line-height: 32px !important;
	}

	.billsitems {
		width: 100%;
		height: 64px;
		border-bottom: 1px solid #f2f2f2;
	}
</style>
